export default {
    common: {
        success: '成功！',
        confirm: '确认',
        cancel: '取消',
        passwordTips: '请输入密码',
        inputTips: '请输入',
        selectTips: '请选择'
    },
    login: {
        title: '豪生酒店管控系统',
        text: 'Welcome欢迎登录',
        login: '登录',
        register: '注册',
        account: '账号',
        password: '密码',
        accountTips: '请输入用户名',
        passwordTips: '请输入密码',
        passwordAgain: '确认密码',
        passwordAgainTips: '请再次输入密码',
        passwordError: '两次密码输入不一致',
        success: '注册成功！',

        qrCode: '二维码登录',
        accountSignIn: '账号密码登录',
        scanSuccess: '已扫描成功,请在手机上按确认登录'

    },
    index: {
        title: '房源预定',
        content: '预定独一无二的房源与体验。',
        tips: '请输入酒店名称',
        search: '搜索'
    },
    nav: {
        index: '首页',
        list: '酒店列表',
        collection: '我的收藏',
        order: '我的订单',
        record: '我的记录',
        wallet: '我的钱包',
        logOut: '注销',
        menu: '目录'
    },
    hotelList: {
        title: '酒店列表',
        confirm: '选择此酒店',
        dialogTitle: '预定房间',
        selectDate: '选择日期：',
        startDate: '开始日期',
        endDate: '结束日期',
        selectRoom: '选择房型',
        province: '来自省份',
        dialogCancel: '取 消',
        dialogConfirm: '确 定',
        select: '请选择',
        success: '预定成功！',
        isolatedHotel: '隔离酒店',

        beijing: '北京市',
        shanghai: '上海市',
        tianjin: '天津市',
        chongqing: '重庆市',
        hebei: '河北省',
        shanxi: '山西省',
        neimenggu: '内蒙古自治区',
        liaoning: '辽宁省',
        jilin: '吉林省',
        heilongjiang: '黑龙江省',
        jiangsu: '江苏省',
        zhejiang: '浙江省',
        anhui: '安徽省',
        fujian: '福建省',
        jiangxi: '江西省',
        shandong: '山东省',
        henan: '河南省',
        hubei: '湖北省',
        hunan: '湖南省',
        guangdong: '广东省',
        guangxi: '广西壮族自治区',
        hainan: '海南省',
        sichuan: '四川省',
        guizhou: '贵州省',
        yunnan: '云南省',
        xizang: '西藏自治区',
        shanxi: '陕西省',
        gansu: '甘肃省',
        ningxia: '宁夏回族自治区',
        qinghai: '青海省',
        xinjiang: '新疆维吾尔族自治区',
        taiwan: '台湾省',
        xianggang: '香港',
        aomen: '澳门',
    },
    hotelDetails: {
        hotelIntroduction: '酒店介绍',
        userEvaluation: '用户评价',
        comment1: '共',
        comment2: '条评论',
        open: '展开',
        stow: '收起',
        reserve: '正常预定',
        reserveBtn: '预定',
        declare: '自申报',
        startDate: '开始日期',
        endDate: '结束日期', 

        checkDate: '入住时间',
        type: '申报类型',
        name: '姓名',
        idNum: '身份证号码',
        tel: '电话',
        email: '电子邮箱',
        tips: '自行申报需提前48小时申报。',
        submitBtn: '提交申报',

        unitPrice: '房间单价',
        price: '订单总价',
        spend: '预计实付款',
        confirmOrder: '确认订单',
        coupon: '个人优惠券',

        payTitle: '请输入钱包密码',
        payment: '当前支付金额'
    },
    isolationList: {
      roomName: '入住房间：'
    },
    orderList: {
        orderId: '订单ID：',
        cancelOrder: '取消订单',
        goPayOrder:'去支付',
        writeComment: '去评论',
        hotelName: '酒店名称：',
        roomType: '房间类型：',
        day: '入住天数：',
        dayNum: '天',
        spend: '实付金额：',
        noOrder: '暂无订单!',
        success: '取消成功！',
        status0: '未支付',
        status1: '已支付',
        status2: '已关闭',
        status3: '已入住',
        status4: '已完成',
    },
    recordList: {
        isolateRecords: '隔离记录',
        isolateRequestRecords: '隔离入住申请记录',
        materialRequestRecords: '物资申请记录',
        isolateID: '隔离ID：',
        isolateStartTime: '隔离开始时间：',
        isolateEndTime: '隔离结束时间：',
        source: '来源地：',
        detectRecords: '检测记录',
        materialRequest: '物资申请',
        applicantName: '申报人姓名：',
        idNum: '身份证号码：',
        tel: '电话：',
        declareID: '申报ID：',
        refuse: '拒绝理由：',
        appliedItems: '申请物品：',
        appliedNum: '申请数量：',
        appliedID: '申请ID：',
        appliedInstructions: '申请说明：',
        auditInstructions: '审核说明：',
        detectionTime: '检测时间',
        detectionType: '检测类型',
        DetectionResults: '检测结果',
        temperature: '体温',
        nucleicAcidDetection: '核酸',
        positive: '阳性',
        negative: '阴性',        

        materialApplication: '物资申请',
        applyMaterial: '申请物资：:',
        applyUnit: '申请单位：',
        applyRemarks: '申请备注：',
        Urgency: '紧急程度：',
    },



    forgetpwd: {
        title: '邮箱验证',
        next: '下一步',
        verificationcode: '验证码',
        text: '已发送验证码至',
        email: '邮箱',
        error: '验证码错误',
        formaterror: '邮箱格式不正确'
    },
    newpwd: {
        title: '密码设置',
        confirm: '确定',
        newpwd: '新密码',
        newpwdagain: '再次输入新密码',
        fail: '修改失败',
        pwdinconsistent: '输入的密码不一致'
    },
    unlock: {
        title: '开门',
        card: '所有门禁卡',
        record: '开门记录',
        dooring: '正在开门',
        sueecss: '开门成功',
        fail: '开门失败'
    },
    my: {
        title: '我的',
        pwdset: '密码设置',
        langset: '语言设置',
        logout: '退出登录'
    },
    editinfo: {
        title: '编辑个人信息',
        perinfo: '个人信息',
        modifyimg: '修改头像',
        name: '姓名',
        sex: '性别',
        department: '部门',
        position: '职位',
        employeenum: '员工编号',
        confirm: '确定',
        cancel: '取消',
        selectsex: '选择性别',
        m: '男',
        f: '女'
    },
    message: {
        title: '消息列表'
    },
    langset: {
        title: '语言设置',
        confirm: '确定'
    },
    carddetails: {
        title: '门禁卡详情',
        time: '开门时间'
    },
    unlockrecord: {
        title: '开门记录',
        search: '搜索',
        inputtip: '根据日期搜索',
        tips: '请选择日期'
    },
    test: {
      tips: '选择日期11'
    },
    wallet: {
        myWallet: '我的钱包',
        tips: '未开通钱包的用户将自动创建账户',
        myBalance: '我的零钱',
        topUp: '充值',
        withdraw: '提现',
    }
}
