export default {
    common: {
        success: 'Success！',
        confirm: 'Confirm',
        cancel: 'Cancel',
        passwordTips: 'Please enter your password',
        inputTips: 'Please enter',
        selectTips: 'Please select'
    },
    login: {
        title: 'Hotel Management System',
        text: 'Welcome',
        login: 'Sign in',
        register: 'Sign up',
        account: 'Account',
        password: 'Password',
        accountTips: 'Please enter your account',
        passwordTips: 'Please enter your password',
        passwordAgain: 'Confirm password',
        passwordAgainTips: 'Please enter your password again',
        passwordError: 'Passwords does not match!',
        success: 'success!',

        qrCode: 'QRcode Sign In',
        accountSignIn: 'Account Sign In',
        scanSuccess: 'Success,Please confirm the login on your phone',

        confirm: 'Login',
        forgetpwd: 'Forget Password',
        numnull: 'Please enter your account',
        pwderror: 'Wrong Account',
        accounterror: 'The user could not be found',
        formaterror: 'The email format is incorrect'
    },
    index: {
        title: 'Reservation',
        content: 'Unique experience',
        tips: 'Please enter hotel name',
        search: 'Search'
    },
    nav: {
        index: 'Index',
        list: 'Hotel List',
        collection: 'My Collection',
        order: 'My Order',
        record: 'My Record',
        wallet: 'My Wallet',
        logOut: 'Log out',
        menu: 'Menu'
    },
    hotelList: {
        title: 'Hotel List',
        confirm: 'Confirm',
        dialogTitle: 'Reservation',
        selectDate: 'Select date:',
        startDate: 'Start',
        endDate: 'End',
        selectRoom: 'Select room:',
        province: 'province:',
        dialogCancel: 'Cancel',
        dialogConfirm: 'Confirm',
        select: 'Select',
        success: 'success!',
        isolatedHotel: 'Isolated Hotel',

        beijing: 'Beijing',
        shanghai: 'Shanghai',
        tianjin: 'Tianjin',
        chongqing: 'Chongqing',
        hebei: 'Hebei',
        shanxi: 'Shanxi',
        neimenggu: 'Neimenggu',
        liaoning: 'Liaoning',
        jilin: 'Jilin',
        heilongjiang: 'Heilongjiang',
        jiangsu: 'Jiangsu',
        zhejiang: 'Zhejiang',
        anhui: 'Anhui',
        fujian: 'Fujian',
        jiangxi: 'Jiangxi',
        shandong: 'Shandong',
        henan: 'Henan',
        hubei: 'Hubei',
        hunan: 'Hunan',
        guangdong: 'Guangdong',
        guangxi: 'Guangxi',
        hainan: 'Hainan',
        sichuan: 'Sichuan',
        guizhou: 'Guizhou',
        yunnan: 'Yunnan',
        xizang: 'Xizang',
        shanxi: 'Shanxi',
        gansu: 'Gansu',
        ningxia: 'Ningxia',
        qinghai: 'Qinghai',
        xinjiang: 'Xinjiang',
        taiwan: 'Taiwan',
        xianggang: 'Hong Kong',
        aomen: 'Macao',
    },
    hotelDetails: {
        hotelIntroduction: 'Hotel Introduction',
        userEvaluation: 'User Evaluation',
        comment1: '',
        comment2: 'Comments',        
        open: 'Open',
        stow: 'Stow',
        reserve: 'Reserve',
        reserveBtn: 'Reserve',
        declare: 'Declare',
        startDate: 'Start Date',
        endDate: 'End Date',

        checkDate: 'Check in time',
        type: 'type',
        name: 'name',
        idNum: 'ID Number',
        tel: 'Tel',
        email: 'email',
        tips: 'Need to submit 48 hours in advance',
        submitBtn: 'Submit',

        unitPrice: 'Unit Price',
        price: 'Price',
        spend: 'Spend',
        confirmOrder: 'Confirm Order',
        coupon: 'Coupon',
        payTitle: 'Please enter the wallet password',
        payment: 'Payment'
        
    },    
    isolationList: {
        roomName: 'Room:'
    },
    orderList: {
        orderId: 'OrderID:',
        goPayOrder:'Go to pay',
        cancelOrder: 'Cancel the order',
        writeComment: 'Write comment',
        hotelName: 'Hotel name:',
        roomType: 'room type:',
        day: 'day:',
        dayNum: 'day',
        spend: 'cost:',
        noOrder: 'No order!',
        success: 'success!',
        status0: 'Unpaid',
        status1: 'Paid',
        status2: 'Closed',
        status3: 'Checked in',
        status4: 'Completed',
    },
    recordList: {
        isolateRecords: 'Isolate Records',
        isolateRequestRecords: 'Isolate Request Records',
        materialRequestRecords: 'Material Request Records',
        isolateID: 'Isolate ID:',
        isolateStartTime: 'Isolate StartTime:',
        isolateEndTime: 'Isolate EndTime:',
        source: 'Source:',
        detectRecords: 'Detect Records',
        materialRequest: 'Material Request',
        applicantName: 'Applicant Name:',
        idNum: 'ID Number:',
        tel: 'Tel:',
        declareID: 'declareID:',
        refuse: 'Reasons for Rejection:',
        appliedItems: 'Applied Items:',
        appliedNum: 'Number:',
        appliedID: 'Applied ID:',
        appliedInstructions: 'Application Instructions:',
        auditInstructions: 'Audit Instructions:',
        detectionTime: 'Detection Time',
        detectionType: 'Detection Type',
        DetectionResults: 'Detection Results',
        temperature: 'Temperature',
        nucleicAcidDetection: 'Nucleic acid detection',
        positive: 'Positive',
        negative: 'Negative',

        materialApplication: 'Material Application',
        applyMaterial: 'Apply Material:',
        applyUnit: 'Apply unit:',
        applyRemarks: 'Apply Remarks:',
        Urgency: 'Urgency:',
    },    



    forgetpwd: {
        title: 'Verification',
        next: 'Next',
        verificationcode: 'Verification Code',
        text: 'Verification Code has been sent to',
        email: 'Email',
        error: 'Wrong Verification Code',
        formaterror: 'The email format is incorrect'
    },
    newpwd: {
        title: 'Password Setting',
        confirm: 'Done',
        newpwd: 'New Password',
        newpwdagain: 'New Password Again',
        fail: 'Operation Failed',
        pwdinconsistent: 'Two passwords you entered do not match'
    },
    unlock: {
        title: 'Unlock',
        card: 'Cards',
        record: 'Access Log',
        dooring: 'Operating',
        sueecss: 'Operation Succeed',
        fail: 'Operation Failed'
    },
    my: {
        title: 'Me',
        pwdset: 'Password',
        langset: 'Language',
        logout: 'Log Out'
    },
    editinfo: {
        title: 'Edit Profile',
        perinfo: 'My Profile',
        modifyimg: 'Profile Photo',
        name: 'Name',
        sex: 'Gender',
        department: 'Department',
        position: 'Position',
        employeenum: 'Staff Number',
        confirm: 'Done',
        cancel: 'Cancel',
        selectsex: 'Gender',
        m: 'Male',
        f: 'Female'
    },
    message: {
        title: 'Notices'
    },
    langset: {
        title: 'Language',
        confirm: 'Done'
    },
    carddetails: {
        title: 'Card Details',
        time: 'Permission Time'
    },
    unlockrecord: {
        title: 'Access Log',
        search: 'Search',
        inputtip: 'Search by Date',
        tips: 'Select Date'
    },
    test: {
        tips: 'Select Date'
    },
    wallet: {
        myWallet: 'My Wallet',
        tips: "Users who don't have a wallet will automatically create an account",
        myBalance: 'My Balance',
        topUp: 'Top Up',
        withdraw: 'Withdraw',
    }    

}
