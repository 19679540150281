<template>
    <div>

        <div class="copyriht-footer">
            Copyriht @ 2022 Hotel management
        </div>

    </div>
</template>

<script>
    export default {
        name: "Footer",
    }
</script>

<style scoped>
    .copyriht-footer {
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #f5f7fa;
        border-top: 1px solid #edeef0;
        font-size: 14px;
    }
</style>